
// Get user from localStorage
const user = JSON.parse(localStorage.getItem("admin__asksophia"));
export const initialState = {
    tools: [],
    tool: null,
    pages: 0,
    user: user ? user : null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    search: '',
    message: ""
}

export const descriptionReducer = (state, action) => {
    switch (action.type) {
        case "ADD_TOOL":
            return {
                ...state,
                isLoading: true,
                isError: false,
                isSuccess: true,
                message: "Tool Added successfully"
            }
        case "EDIT_TOOL":
            return {
                ...state,
                isLoading: true,
                isError: false,
                isSuccess: true,
                message: action.payload
            }

        case "DELETE_TOOL":
            return {
                ...state,
                tools: state.tools.filter((tool) => tool._id !== action.payload),
                isLoading: true,
                isError: false,
                isSuccess: true,
                message: "Tool deleted successfully"
            }
        
        case "FETCH_TOOLS":
            return {
                ...state,
                tools: action.tools,
                pages: action.pages,
                isLoading: false,
                isError: false,
                isSuccess: true
            }
        case "FILTER_TOOLS":
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: true,
                search: action.search
            }
           
        case "ERROR":
            return {
                ...state,
                isLoading: false,
                isError: true,
                isSuccess: false,
                message: action.payload
            }
        case "RESET":
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: false,
                message: ""
            };

        default:
            throw new Error("You are dispatching something that is not in reducer");
    }
}