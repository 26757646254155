import { Navigate } from 'react-router-dom';
import { UserContext } from '../../../App';
import { useContext } from 'react';

const IndexRoute = () => {
  const { state } = useContext(UserContext);
  
  if (state.user && state.user.role === 'admin') {
    return <Navigate to="/admin/dashboard" replace />;
  } else {
    return <Navigate to="/admin/login" replace />;
  }
};

export default IndexRoute;