import axios from "axios";
axios.defaults.withCredentials = true;

export const fetchAllPlans = async (dispatch, page) => {
    
    const url = `${process.env.REACT_APP_BACKEND_URL}plans?page=${page}`;
    try {
        const response = await axios.get(url);
        dispatch({ type: "FETCH_PLANS", plans: response.data.plans, pages: response.data.pages });
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message })
    }
}


export const postPlan = async (data, dispatch) => {
    try {
        const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}plans`, data);
        dispatch({ type: "ADD_PLAN" });
        return result;
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}
export const editPlan = async (data, dispatch) => {
    try {
        const result = await axios.put(`${process.env.REACT_APP_BACKEND_URL}plans/` + data._id, data);
        dispatch({ type: "EDIT_PLAN" });
        return result;
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}

export const deletePlan = async (id, dispatch) => {
    try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}plans/` + id);
        dispatch({ type: "DELETE_PLAN", payload: id });
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}

