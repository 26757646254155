import axios from "axios";
axios.defaults.withCredentials = true;

export const fetchAllTags = async (dispatch, page, tag_type) => {
    
    const url = `${process.env.REACT_APP_BACKEND_URL}tags?page=${page}&tag_type=${tag_type}`;
    try {
        const response = await axios.get(url);
        dispatch({ type: "FETCH_TAGS", tags: response.data.tags, pages: response.data.pages });
        return response.data.tags;
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message })
    }
}


export const postTag = async (data, dispatch) => {
    try {
        const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}tags`, data);
        dispatch({ type: "ADD_TAG" });
        return result;
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}

export const editTag = async (data, dispatch) => {
    try {
        const result = await axios.put(`${process.env.REACT_APP_BACKEND_URL}tags/` + data._id, data);
        dispatch({ type: "EDIT_TAG" });
        return result;
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}

export const deleteTag = async (id, dispatch) => {
    try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}tags/` + id);
        dispatch({ type: "DELETE_TAG", payload: id });
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}
