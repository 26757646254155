
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';

import DashboardCards from '../components/Dashboard/DashboardCards';
import DashboardCharts from '../components/Dashboard/DashboardCharts';
import DashboardAccounts from '../components/Dashboard/DashboardAccounts';
import useGetTotal from '../../hooks/useGetTotal';


const AdminDashboard = () => {
   const [totals, setTotals]=useState({
    users:0, prompts:0, comments:0, projects:0
   })
   const {users, prompts, comments, projects} = totals;

   useGetTotal(setTotals);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <Helmet>
                <title>Dashboard | AskSophia</title>
            </Helmet>
            <section className="bg-[#F8F8F8] py-[30px] px-[40px] min-h-screen">
                <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2'>
                    <DashboardCards title="Users" total={users} />
                    <DashboardCards title="Prompts" total={prompts} />
                    <DashboardCards title="Projects" total={projects} />
                    <DashboardCards title="Comments" total={comments} />
                </div>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-2 mt-[20px]'>
                    <DashboardCharts />
                    <DashboardCharts />
                </div>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-2 mt-[20px]'>
                    <DashboardAccounts />
                </div>
            </section>
        </>
    )
}

export default AdminDashboard