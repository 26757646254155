import { Navigate,useLocation, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../App';

const PrivateAdminRoute = () => {
  const { state } = useContext(UserContext);
  const location = useLocation();

  if (!state.user) {
    return <Navigate to='/admin/login' state={{ from: location }} replace />;
  }
  else if (state.user.role === 'admin') {
    return <Outlet />;
  } 
  else {
    return <Navigate to='/admin/login' state={{ from: location }} replace />;
  }

};
export default PrivateAdminRoute;