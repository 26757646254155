
import { useEffect, useReducer, useState } from 'react'
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
    Card,
    Typography,
    CardBody,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import { toast } from 'react-toastify';
import SearchBread from '../components/layouts/SearchBread';
import EditTone from './EditTone';
import { initialState, toneReducer } from '../../Reducers/toneReducer';
import { deleteTone, editTone, fetchAllTones, postTone } from '../../api/toneService';

const TABLE_HEAD = ["Title","Description", "Actions"];


const Tones = () => {
    const [toneState, dispatch] = useReducer(toneReducer, initialState)
    const [searchText, setSearchText] = useState('');
    const [editTones, setEditTone] = useState(null);
    const [open, setOpen] = useState(false);
    const { tones, isError, isSucess, message } = toneState;

    useEffect(() => {
        if (isError) {
            toast.error(message)
        } else if (isSucess) {
            toast.success("Tone deleted")
        }
        dispatch({ type: "RESET" })
    }, [isError, isSucess, dispatch, message]);

    const removeTone = async (id) => {
        alert("Are you sure you want to delete this tone?")
        await deleteTone(id, dispatch);
    }

    const handleOpen = (data) => {
        if (data) {
            setEditTone(data)
        }
        setOpen(!open)
    }

    const handleClose = () => {
        setEditTone(null)
        setOpen(!open)
    }

    const getTones = async () => {
        await fetchAllTones(dispatch);
    }

    useEffect(() => {
        getTones()
    }, [])

    const handleSubmit = async (data, type) => {
        if (type === "edit") {
            await editTone(data, dispatch);
        } else {
            await postTone(data, dispatch);
        }
        handleClose()
        getTones()
    }

    return (
        <>
            <section className="bg-[#F8F8F8] py-[30px] px-[40px]">
                <SearchBread title="Tones List" searchText={searchText} setSearchText={setSearchText} />
                <div onClick={() => handleOpen(null)}
                    className='cursor-pointer w-[200px] text-center text-white 
                    bg-blue-400 rounded-full mb-2 px-4 py-2'>Add New Tone</div>

                {toneState && tones && tones.length > 0 ? <Card className="h-auto w-full">
                    <CardBody className="overflow-scroll px-0">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal flex leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tones.map(
                                    (tone, index) => {
                                        const isLast = index === tones.length - 1;
                                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                                        return (
                                            <tr key={tone._id}>
                                                <td className={`${classes} text-center`}>
                                                    <div className="flex">
                                                        <p className={`text-md font-bold`}>
                                                            {tone.title}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className={`${classes} text-center`}>
                                                    <div className="flex">
                                                        <p className={`text-md font-bold`}>
                                                            {tone.description}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className={`${classes} flex`}>
                                                    <Tooltip content="Edit Tone">
                                                        <IconButton onClick={() => handleOpen(tone)} variant="text" color="blue-gray">
                                                            <PencilIcon className="h-4 w-4" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip content="delete Tone">
                                                        <IconButton onClick={() => removeTone(tone._id)} variant="text" color="blue-gray">
                                                            <TrashIcon className="h-4 w-4" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </CardBody>
                </Card> : <>
                    <div className="flex flex-col items-center justify-center">
                        <img src="/images/icons/saved.png" alt="empty" className="my-4" />
                        <p className="text-[#707070] text-[20px] font-medium">No Tones Found</p>
                    </div>
                </>}
            </section>
            <EditTone
                open={open}
                editTone={editTones}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleSubmit={handleSubmit} />
        </>
    )
}

export default Tones