import axios from "axios";
axios.defaults.withCredentials = true;

export const fetchAllTones = async (dispatch) => {
    
    const url = `${process.env.REACT_APP_BACKEND_URL}tones`;
    try {
        const response = await axios.get(url);
        dispatch({ type: "FETCH_TONES", payload: response.data});
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message })
    }
}


const getToken = () => {
    const user = JSON.parse(localStorage.getItem("admin__asksophia"))
    return user.token;
}

export const postTone = async (data, dispatch) => {
    try {
        const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}tones`, data);
        dispatch({ type: "ADD_TONE" });
        return result;
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}

export const editTone = async (data, dispatch) => {
    try {
        const result = await axios.put(`${process.env.REACT_APP_BACKEND_URL}tones/` + data._id, data);
        dispatch({ type: "EDIT_TONE" });
        return result;
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}

export const deleteTone = async (id, dispatch) => {
    try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}tones/` + id);
        dispatch({ type: "DELETE_TONE", payload: id });
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}

