
import { useEffect, useReducer, useState } from 'react'
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
    Card,
    Typography,
    Button,
    CardBody,
    CardFooter,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import { toast } from 'react-toastify';
import SearchBread from '../components/layouts/SearchBread';
import AddToolDesc from './AddToolDesc';
import { deleteTool, editTool, fetchAllTools, postTool } from '../../api/descriptionService';
import { descriptionReducer, initialState } from '../../Reducers/descriptionReducer';
import { Helmet } from 'react-helmet-async';

const TABLE_HEAD = ["Name", "Link","Actions"];


const ToolsDesc = () => {
    const [toolState, dispatch] = useReducer(descriptionReducer, initialState)
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [editTools, setEditTool] = useState(null);
    const [open, setOpen] = useState(false);
    const { tools, isError, isSucess, pages, message } = toolState;

    useEffect(() => {
        if(isError){
            toast.error(message)
        } else if(isSucess) {
            toast.success("Tool deleted")
        }
        dispatch({type:"RESET"})
    }, [isError, isSucess , dispatch]);

    const removeTool = async (id) => {
        alert("Are you sure you want to delete this tool?")
        await deleteTool(id, dispatch);
    }

    const handleOpen = (data) => {
       if(data){
        setEditTool(data)
       }
        setOpen(!open)
    }

    const handleClose = () => {
        setEditTool(null)
        setOpen(!open)
    }
    
    const getTools = async () => {
        await fetchAllTools(dispatch, page);
    }

    useEffect(() => {
        getTools()
    }, [page])

    const handleSubmit = async (data, type)=>{
       if(type==="edit"){
        console.log(data)
        await editTool(data, dispatch);
       } else {
        await postTool(data, dispatch);
       }
       setEditTool(null)
        handleOpen()
        getTools()
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <Helmet>
                <title>Tools Description | AskSophia</title>
            </Helmet>
            <section className="bg-[#F8F8F8] py-[30px] px-[40px] min-h-screen">
                <SearchBread title="Tools Descritpion List" searchText={searchText} setSearchText={setSearchText} />
                <div onClick={()=>handleOpen(null)}
                className='cursor-pointer w-[200px] text-center text-white bg-blue-400 rounded-full mb-2 px-4 py-2'>Add New Tool</div>

          {toolState && tools && tools.length > 0 ? <Card className="h-auto w-full">
                <CardBody className="overflow-scroll px-0">
                    <table className="w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal flex justify-center leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tools.map(
                                (tool, index) => {
                                    const isLast = index === tools.length - 1;
                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={tool._id}>
                                           
                                            <td className={`${classes} text-center`}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                {tool.name?.title}
                                                </Typography>
                                            </td>
                                            <td className={`${classes} text-center`}>
                                            <div className="flex justify-center items-center gap-3">
                                                    <Typography variant="small" color="pink" className="font-bold">
                                                        {tool.link}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={`${classes} flex justify-center`}>
                                            <Tooltip content="Edit Tool">
                                                   <IconButton onClick={()=>handleOpen(tool)} variant="text" color="blue-gray">
                                                        <PencilIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip>
                                              <Tooltip content="delete Category">
                                                    <IconButton  onClick={()=>removeTool(tool._id)} variant="text" color="blue-gray">
                                                        <TrashIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                </CardBody>
                <CardFooter className={`${page === pages && page === 1 ? 'hidden' :'flex'}  items-center justify-end border-t border-blue-gray-50 p-4`}>
                    <Button onClick={()=>setPage(page-1)} disabled={page === 1} className='mr-2' variant="outlined" color="blue-gray" size="sm">
                        Previous
                    </Button>
                    <Button onClick={()=>setPage(page+1)} disabled={page === pages} variant="outlined" color="blue-gray" size="sm">
                        Next
                    </Button>
                </CardFooter>
            </Card> : <>
            <div className="flex flex-col items-center justify-center">
                <img src="/images/icons/saved.png" alt="empty" className="my-4" />
                <p className="text-[#707070] text-[20px] font-medium">No Tools Found</p>
            </div>
            </>}
            </section>
        <AddToolDesc 
        open={open}
        editTools={editTools} 
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleSubmit={handleSubmit} />
        </>
    )
}

export default ToolsDesc