import { useContext } from "react";
import { admin_sidebar } from "../../../config/sidebar";
import SidebarTools from "../items/SidebarTools";
import { UserContext } from "../../../App";

const Sidebar = () => {
    const { state } = useContext(UserContext);
    
    return (
        <div className="w-[288px] bg-cyan-900 text-white fixed px-[24px] lg:flex lg:flex-col hidden">
            <div className="flex items-center h-[94px] border-b border-[#2C3257]">
                <img className="w-[150px]" src="/images/white.png" alt="asksophia" />
            </div>
          {state.user && state.user.role === 'admin'
            && <>
           <div className="flex flex-col" style={{ minHeight: "100vh" }}>
                <div className="">
                    <div className="flex flex-col border-b border-[#2C3257] mb-[30px]">
                        {admin_sidebar.map((item) => {
                            return <SidebarTools key={item.id} title={item.title} url={item.url} />
                        })}
                    </div>
                </div>
            </div>
            </> 
          }
        </div>
    )
}
export default Sidebar