import axios from "axios";
axios.defaults.withCredentials = true;

export const fetchAllTools = async (dispatch, page) => {
    
    const url = `${process.env.REACT_APP_BACKEND_URL}tools?page=${page}`;
    try {
        const response = await axios.get(url);
        dispatch({ type: "FETCH_TOOLS", tools: response.data.tools, pages: response.data.pages });
        return response.data.tools;

    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message })
    }
}

const getToken = () => {
    const user = JSON.parse(localStorage.getItem("admin__asksophia"))
    return user.token;
}


export const postTool = async (data, dispatch) => {
    try {
        const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}tools`, data);
        dispatch({ type: "ADD_TOOL" });
        return result;
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}
export const editTool = async (data, dispatch) => {
    try {
        const result = await axios.put(`${process.env.REACT_APP_BACKEND_URL}tools/` + data._id, data);
        dispatch({ type: "EDIT_TOOL" });
        return result;
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}

export const deleteTool = async (id, dispatch) => {
    try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}tools/` + id);
        dispatch({ type: "DELETE_TOOL", payload: id });
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}

