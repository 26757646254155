import axios from "axios";
axios.defaults.withCredentials = true;

export const fetchAllCategories = async (dispatch, page) => {
    
    const url = `${process.env.REACT_APP_BACKEND_URL}categories?page=${page}`;
    try {
        const response = await axios.get(url);
        dispatch({ type: "FETCH_CATEGORIES", categories: response.data.categories, pages: response.data.pages });
        return response.data.categories;
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message })
    }
}

export const postCategory = async (data, dispatch) => {
    try {
        const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}categories`, data);
        dispatch({ type: "ADD_CATEGORY" });
        return result;
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}
export const editCategory = async (data, dispatch) => {
    try {
        const result = await axios.put(`${process.env.REACT_APP_BACKEND_URL}categories/` + data._id, data);
        dispatch({ type: "EDIT_CATEGORY" });
        return result;
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}



export const deleteCategory = async (id, dispatch) => {
    try {
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}categories/` + id);
        dispatch({ type: "DELETE_CATEGORY", payload: id });
    } catch (error) {
        dispatch({ type: "ERROR", payload: error.response.data.message });
    }
}

