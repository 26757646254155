import {
    Card,
    Typography,
    CardBody,
} from "@material-tailwind/react";

const TABLE_HEAD = ["Title", "Status", "Date"];


const DashboardAccounts = () => {
  return (
    <div className='flex items-center bg-white rounded-[10px] px-4 py-3 border border-gray-100'>
        <h3 className="text-gray-700 text-[18px] font-bold">Recent prompts</h3>
        {/* <div className="">
                {promptState && prompts && prompts.length > 0 ? <Card className="h-auto w-full">
                    <CardBody className="overflow-scroll px-0">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal flex leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {prompts.map(
                                    (prompt, index) => {
                                        const isLast = index === prompts.length - 1;
                                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                        return (
                                            <tr key={prompt._id}>
                                                <td className={`${classes}`}>
                                                    <p className="font-bold text-sm text-black">
                                                        {prompt.title}
                                                    </p>
                                                </td>
                                                <td className={`${classes}`}>
                                                    <p className="text-sm font-normal 
                                                    overflow-hidden whitespace-nowrap"
                                                    style={{
                                                        textOverflow: "ellipsis",
                                                        width:"300px"

                                                    }}>
                                                        {truncateString(prompt.description, 40)}
                                                    </p>
                                                </td>
                                                <td className={`${classes}`}>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {moment(prompt.createdAt).fromNow()}
                                                    </Typography>
                                                </td>
                                               
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </CardBody>
                </Card> : <>
                    <div className="flex flex-col items-center justify-center">
                        <img src="/images/icons/saved.png" alt="empty" className="my-4" />
                        <p className="text-[#707070] text-[20px] font-medium">No Recent info Found</p>
                    </div>
                </>}
            </div> */}
    </div>
  )
}

export default DashboardAccounts