import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Select, Option ,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { putUser } from '../../../api/userService';
import { UserContext } from '../../../App';

const EditUser = ({ open, handleOpen, user, handleSubmit }) => {
    const {state, dispatch} = useContext(UserContext);

    const [formData, setFormData] = useState({
        username: '', role:"", email: '', status: '', plan: '', words: 0
    });

  const { username, role,  email, status, plan, words } = formData;
   
    useEffect(()=>{
        if(user){
           setFormData({...formData, _id:user._id, username:user.username, 
            role:user.role, email:user.email, status:user.status, 
            plan:user.plan, words:user.words})
        }
    }, [user])

    const handleChange = (e)=>{
        setFormData({...formData, [e.target.name]:e.target.value})
    }
    const updateUser = async ()=>{
        await handleSubmit(state.user.role, formData, dispatch);
    }

    return (
        <>
            <Dialog open={open} handler={handleOpen}>
                <div className="flex items-center justify-between">
                    <DialogHeader>Edit User: <span className='text-indigo-500 ml-2'>{user?.username}</span></DialogHeader>
                    <XMarkIcon className="mr-3 h-5 w-5" onClick={handleOpen} />
                </div>
                <DialogBody divider>
                    <div className="grid grid-cols-2 gap-6">
                        <Input label="Username" name='username' value={username} onChange={handleChange} required/>
                        <Input label="Email" name="email" type="email" value={email} onChange={handleChange} required/>
                        <select value={status} onChange={handleChange}
                        className="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <option value="" disabled>Status</option>
                            <option value="active">Active</option>
                            <option value="not active">Not Active</option>
                        </select>
                        <select value={plan} onChange={handleChange} required
                        className="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <option value="" disabled>Plan</option>
                            <option value="free">Free</option>
                            <option value="basic">Basic</option>
                            <option value="premium">Premium</option>
                        </select>
                        <select value={role} onChange={handleChange} required
                        className="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <option value="" disabled>Role</option>
                            <option value="admin">Admin</option>
                            <option value="guest">Guest</option>
                        </select>
                        
                        <Input type="number" label="Words" value={words} onChange={handleChange} required/>
                    </div>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="outlined" color="red" onClick={handleOpen}>
                        close
                    </Button>
                    <Button variant="gradient" color="green" onClick={updateUser}>
                        Update
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default EditUser