import { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

const EditPlan = ({ open, handleOpen, editPlan, handleSubmit, handleClose }) => {

    const [price, setPrice] = useState(0);
    const [title, setTitle] = useState("");
    const [plan_id, setPlanId] = useState("");
    const [plan_type, setPlanType] = useState("");
    const [pros, setPros] = useState([""]);

    useEffect(() => {
        if (editPlan) {
            setPrice(editPlan.price);
            setPros(editPlan.pros);
            setTitle(editPlan.title);
            setPlanId(editPlan.plan_id);
            setPlanType(editPlan.plan_type);
        }
    }, [editPlan])

    const handleAddPros = () => {
        setPros(prevPros => [...prevPros, '']);
    };

    const handleRemovePros = (indexToRemove) => {
        setPros(prevPros => prevPros.filter((pros, index) => index !== indexToRemove));
    };

    const handleProsChange = (indexToChange, newValue) => {
        setPros(prevPros => prevPros.map((pros, index) => index === indexToChange ? newValue : pros));
    };


    const submitFunction = async (type) => {
        await handleSubmit({_id:editPlan?._id, title, price, pros, plan_id, plan_type}, type);
        setPrice(0);
        setPros([""]);
        setTitle("");
        setPlanId("");
        setPlanType("");
    }

    return (
        <>
            <Dialog open={open} handler={handleOpen}>
                <div className="flex items-center justify-between">
                    <DialogHeader>{editPlan ? 'Edit' : 'Add'} Plan</DialogHeader>
                    <XMarkIcon className="mr-3 h-5 w-5" onClick={handleClose} />
                </div>
                <DialogBody divider>
                    <div className="grid grid-cols-2 gap-6">
                    <div className="">
                            <label htmlFor="title" className="block mb-[10px] text-[15px] font-semibold text-gray-900">Title (Basic Plan)</label>
                                <div className="flex">
                                    <input
                                        required
                                        type="text"
                                        name="title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        className="mr-[20px] mb-[8px] border border-gray-500 outline-0 text-gray-800 rounded-[8px] py-[14px] px-4 w-full"
                                    />
                                </div>
                        </div>
                    <div className="">
                            <label htmlFor="plan_id" className="block mb-[10px] text-[15px] font-semibold text-gray-900">Plan Id</label>
                                <div className="flex">
                                    <input
                                        required
                                        type="text"
                                        name="plan_id"
                                        value={plan_id}
                                        onChange={(e) => setPlanId(e.target.value)}
                                        className="mr-[20px] mb-[8px] border border-gray-500 outline-0 text-gray-800 rounded-[8px] py-[14px] px-4 w-full"
                                    />
                                </div>
                        </div>
                    <div className="">
                            <label htmlFor="plan_type" className="block mb-[10px] text-[15px] font-semibold text-gray-900">Plan Type</label>
                                <div className="flex">
                                    <input
                                        required
                                        type="text"
                                        name="plan_type"
                                        placeholder='it should be free/basic/premium'
                                        value={plan_type}
                                        onChange={(e) => setPlanType(e.target.value)}
                                        className="mr-[20px] mb-[8px] border border-gray-500 outline-0 text-gray-800 rounded-[8px] py-[14px] px-4 w-full"
                                    />
                                </div>
                        </div>
                        <div className="">
                            <label htmlFor="price" className="block mb-[10px] text-[15px] font-semibold text-gray-900">Price</label>
                                <div className="flex">
                                    <input
                                        required
                                        name="price"
                                        type="number"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                        className="mr-[20px] mb-[8px] border border-gray-500 outline-0 text-gray-800 rounded-[8px] py-[14px] px-4 w-full"
                                    />
                                </div>
                        </div>
                        
                        <div className="">
                            <label htmlFor="pros" className="block mb-[10px] text-[15px] font-semibold text-gray-900">Pros</label>
                            {pros.map((pros, index) => (
                                <div key={index} className="flex">
                                    <input
                                        type="text"
                                        value={pros}
                                        onChange={(e) => handleProsChange(index, e.target.value)}
                                        className="mr-[20px] mb-[8px] border border-gray-500 outline-0 text-gray-800 rounded-[8px] py-[14px] px-4 w-full"
                                    />
                                    <div className="flex">
                                        <div
                                            onClick={() => handleRemovePros(index)}
                                            className="cursor-pointer text-[28px] mr-[10px]"
                                        >-</div>
                                        <div
                                            onClick={handleAddPros}
                                            className="cursor-pointer text-[24px] mr-[10px]"
                                        >+</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="outlined" color="red" onClick={handleClose}>
                        close
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => submitFunction(editPlan ? 'edit' : 'post')}>
                        {editPlan ? 'Update' : 'Add'}
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default EditPlan