import { useEffect, useReducer, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import SearchBread from '../components/layouts/SearchBread';
import moment from 'moment';
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
    Card,
    Typography,
    Button,
    CardBody,
    CardFooter,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import { deletePrompt, fetchAllPrompts, editPrompt } from '../../api/promptService';
import { promptReducer, initialState } from '../../Reducers/promptReducer';
import { toast } from 'react-toastify';
import EditPrompt from './EditPrompt';

const TABLE_HEAD = ["Title", "Description", "Date", "Actions"];


const Prompts = () => {
    const [promptState, dispatch] = useReducer(promptReducer, initialState)
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [putPrompt, setEditPrompt] = useState(null);

    const { prompts, isError, isSucess, pages } = promptState;

    function truncateString(str, num) {
        if (str.length <= num) {
          return str;
        }
        return str.slice(0, num) + '...';
      }

    useEffect(() => {
        if (isError) {
            toast.error("An error occured")
        } else if (isSucess) {
            toast.success("Prompt deleted")
        }
        dispatch({ type: "RESET" })
    }, [isError, isSucess, dispatch]);

    const removePrompt = async (id) => {
        alert("Are you sure you want to delete this prompt?")
        await deletePrompt(id, dispatch);
    }

    const handleOpen = (data) => {
        if (data) {
            setEditPrompt(data)
        }
        setOpen(!open)
    }
    const handleClose = () => {
        setEditPrompt(null)
        setOpen(!open)
    }
    const getPrompts = async () => {
        await fetchAllPrompts(null, dispatch, page);
    }

    useEffect(() => {
        getPrompts()
    }, [page])

    const handleSubmit = async (id, data) => {
        await editPrompt(id, data, dispatch);
        handleClose()
        getPrompts()
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <Helmet>
                <title>Prompts | AskSophia</title>
            </Helmet>
            <section className="bg-[#F8F8F8] py-[30px] px-[40px] min-h-screen">
                <SearchBread title="All Prompts" searchText={searchText} setSearchText={setSearchText} />
                {promptState && prompts && prompts.length > 0 ? <Card className="h-auto w-full">
                    <CardBody className="overflow-scroll px-0">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal flex leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {prompts.map(
                                    (prompt, index) => {
                                        const isLast = index === prompts.length - 1;
                                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                        return (
                                            <tr key={prompt._id}>
                                                <td className={`${classes}`}>
                                                    <p className="font-bold text-sm text-black">
                                                        {prompt.title}
                                                    </p>
                                                </td>
                                                <td className={`${classes}`}>
                                                    <p className="text-sm font-normal 
                                                    overflow-hidden whitespace-nowrap"
                                                    style={{
                                                        textOverflow: "ellipsis",
                                                        width:"300px"

                                                    }}>
                                                        {truncateString(prompt.description, 40)}
                                                    </p>
                                                </td>
                                                <td className={`${classes}`}>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {moment(prompt.createdAt).fromNow()}
                                                    </Typography>
                                                </td>
                                                <td className={`${classes} flex justify-center`}>
                                                    <Tooltip content="Edit Prompt">
                                                        <div className='cursor-pointer' onClick={() => handleOpen(prompt)}>
                                                            <IconButton variant="text" color="blue-gray">
                                                                <PencilIcon className="h-4 w-4" />
                                                            </IconButton>
                                                        </div>
                                                    </Tooltip>
                                                    <Tooltip content="delete Prompt">
                                                        <IconButton onClick={() => removePrompt(prompt._id)} variant="text" color="blue-gray">
                                                            <TrashIcon className="h-4 w-4" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter className={`${page === pages && page === 1 ? 'hidden' : 'flex'}  items-center justify-end border-t border-blue-gray-50 p-4`}>
                        <Button onClick={() => setPage(page - 1)} disabled={page === 1} className='mr-2' variant="outlined" color="blue-gray" size="sm">
                            Previous
                        </Button>
                        <Button onClick={() => setPage(page + 1)} disabled={page === pages} variant="outlined" color="blue-gray" size="sm">
                            Next
                        </Button>
                    </CardFooter>
                </Card> : <>
                    <div className="flex flex-col items-center justify-center">
                        <img src="/images/icons/saved.png" alt="empty" className="my-4" />
                        <p className="text-[#707070] text-[20px] font-medium">No Prompts Found</p>
                    </div>
                </>}
            </section>
            <EditPrompt open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleSubmit={handleSubmit} 
                editPrompt={putPrompt} />
        </>
    )
}

export default Prompts