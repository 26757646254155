import { useContext, useState } from "react";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import { UserContext } from "../../../App";
import {
    ContactSupportOutlined,
    CampaignOutlined,
    PersonOutlineOutlined, LogoutOutlined,
} from '@mui/icons-material';
import MobileNav from "./MobileNav";
import { Logout } from "../../../api/userService";


const Header = () => {
    const { state, dispatch } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    
    const onLogout = async () => {
        await Logout(state, dispatch);
        navigate("/admin/login");
    }
    if (!state.user) {
        return (<Navigate to="/admin/login" />)
    }
    return (
        <header className="px-[28px]  bg-white h-[94px] border-b border-[#E8E8E8]">
            <nav className="dashNav lg:flex hidden h-full items-center justify-between">
                {state.user && state.user.role == "admin" &&  <>
                <div></div></>}
                <div className="flex">
                    <NavLink to="/admin/contact-us" className="flex items-center text-gray-400 cursor-pointer px-[30px]">
                        <span className="mr-3">
                            <CampaignOutlined />
                        </span>
                        <p className="text-[16px] leading-[24px]">Feedback</p>
                    </NavLink>
                    {/* <NavLink to="/contact-us" className="flex items-center text-gray-300 cursor-pointer px-[30px]">
                        <span>
                            <ContactSupportOutlined />
                        </span>
                    </NavLink> */}
                    <div className="relative">
                        <img className="peer cursor-pointer w-8 h-8 rounded-full" src="/images/sophia.png" alt="user" />
                        <div style={{ zIndex: 12 }}
                         className="hidden peer-hover:flex hover:flex
                        w-[230px] top-8 right-0 absolute p-4 rounded-[10px]
                        flex-col bg-white drop-shadow-lg">
                            <Link to="/profile" className="flex items-center p-2 hover:bg-gray-200">
                                <span className="mr-3">
                                    <PersonOutlineOutlined />
                                </span>
                                Profile</Link>
                            <Link to="/admin/contact-us" className="flex items-center p-2 hover:bg-gray-200">
                                <span className="mr-3">
                                    <ContactSupportOutlined />
                                </span>
                                Contact us</Link>
                            <p onClick={onLogout} className="flex text-gray-400 cursor-pointer items-center p-2 hover:bg-gray-200">
                                <span className="mr-3">
                                    <LogoutOutlined />
                                </span>
                                Log Out</p>
                        </div>
                    </div>
                </div>
            </nav>
            <MobileNav onLogout={onLogout} open={open} setOpen={setOpen} />
        </header>
    )
}
export default Header