import { useEffect, useState, useReducer } from 'react';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { toolsReducer, initialState } from '../../Reducers/toolsReducer';
import { fetchAllTools } from '../../api/toolsService';
import Select from 'react-select'


const AddToolDesc = ({ open, handleOpen, editTools, handleSubmit, handleClose }) => {

    const [toolState, dispatch] = useReducer(toolsReducer, initialState)
    const [formData, setFormData] = useState({ name: "64a3fa86a388def591194ce7", link: ""});
    const [fields, setFields] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    const [tools, setTools] = useState([]);

    const { link, name } = formData;
    function removeField(index) {
        setFields(fields.filter((field, i) => i !== index));
    }

    const getTools = async () => {
        const response = await fetchAllTools(dispatch, null);
        setTools(response)
    }

    useEffect(() => {
        getTools();
        if (editTools) {
            setFields(editTools.fields);
            setFormData({ _id:editTools._id, name: editTools.name._id, link: editTools.link });
          }else {
            setFields([]);
            setFormData({ name: "64a3fa86a388def591194ce7", link: "" });
          }
    }, [open])

    function addField() {
        setFields([...fields, { type: 'input', inputType: 'text', label: '', placeholder: '' }]);
    }

    function updateField(index, type, value) {
        const newFields = [...fields];
        newFields[index][type] = value;
        setFields(newFields);
    }


    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (selectedOption) {
          setFormData({ ...formData, name: selectedOption.value });
        }
      };
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    

    const submitFunction = async (type) => {
        await handleSubmit({_id:editTools?._id, name, link, fields}, type);
        setFormData({ name: "64a3fa86a388def591194ce7", link: "" });
        setFields([]);
    }

    return (
        <>
      
            <Dialog open={open} handler={handleOpen} size="xl" >
                <div className="flex items-center justify-between">
                    <DialogHeader>{editTools ? 'Edit' : 'Add'} Tool: <span className='text-indigo-500 ml-2'>{editTools?.title}</span></DialogHeader>
                    <XMarkIcon className="mr-3 h-5 w-5" onClick={handleClose} />
                </div>
                <DialogBody className='overflow-y-scroll h-[65vh]' divider>
                    <form onSubmit={handleSubmit}>
                        {fields.map((field, index) => (
                                <div key={index} className="grid grid-cols-4 gap-6 items-center">
                                    <div className='mb-6 col-span-1'>
                                        <select
                                            value={field.type}
                                            className="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            onChange={e => updateField(index, 'type', e.target.value)}>
                                            <option value="input">Input</option>
                                            <option value="textarea">Textarea</option>
                                        </select>
                                    </div>
                                    <div className='mb-6 col-span-3'>
                                        {field.type === "input" &&
                                            <div className='mb-4'>
                                                <label>Input Type</label>
                                                <select
                                                    value={field.inputType}
                                                    className="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                                    onChange={e => updateField(index, 'inputType', e.target.value)}>
                                                    <option value="number">Number</option>
                                                    <option value="text">Text</option>
                                                </select>
                                            </div>
                                        }
                                        <div className='mb-4'>
                                            <label>Title of the tool input</label>
                                            <input type="text" value={field.label}
                                                placeholder="Title of the tool"
                                                className="outline-0 bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                onChange={e => updateField(index, 'label', e.target.value)} />
                                        </div>
                                        <div className='mb-4'>
                                            <label>Placeholder of the tool input</label>
                                            <input type="text"
                                                placeholder="Placeholder"
                                                className="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                value={field.placeholder}
                                                onChange={e => updateField(index, 'placeholder', e.target.value)} />
                                        </div>
                                        <button
                                            type="button"
                                            className='bg-red-500 text-white font-medium text-[17px] px-4 py-2 rounded-md hover:bg-red-400'
                                            onClick={() => removeField(index)}>Remove</button>
                                    </div>
                                </div>
                        ))}
                        <div className='grid grid-cols-2 gap-2 mb-6'>
                            <div className=''>
                            <label>Tool name:</label>
                            {tools && tools.length > 0 && <Select 
                              value={selectedOption}
                              onChange={handleChange}
                            options={tools.map(tool => 
                                ({ value: tool._id, label: tool.title }))} 
                                />}

                            </div>
                            <div className=''>
                                <label>Link url</label>
                                <input 
                                type="text"
                                value={link}
                                name="link"
                                className="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className='w-full flex items-center justify-center'>
                            <button
                                type="button"
                                className='bg-green-500 text-white font-medium text-[17px] px-4 py-2 rounded-md hover:bg-green-400'
                                onClick={addField}>Add new Field</button>
                        </div>
                    </form>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="outlined" color="red" onClick={handleClose}>
                        close
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => submitFunction(editTools ? 'edit' : 'add')}>
                        {editTools ? 'Update' : 'Submit'}
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default AddToolDesc

