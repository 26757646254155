import { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
const AddTag = ({ open, handleOpen, editTags, handleSubmit }) => {

    const [formData, setFormData] = useState({
        title: '', color:"", bgColor:"", tag_type:"personal", status: 'active',
    });

    const { title, status, color, bgColor, tag_type} = formData;
   
    useEffect(()=>{
        if(editTags){
           setFormData({...formData, _id:editTags._id, 
            title:editTags.title, status:editTags.status, color:editTags.color,
            bgColor:editTags.bgColor, tag_type:editTags.tag_type})
        }
    }, [editTags])

    const handleChange = (e)=>{
        setFormData({...formData, [e.target.name]:e.target.value})
    }
    const submitFunction = async (type)=>{
        await handleSubmit(formData, type);
        setFormData({title: '', color:"", bgColor:"", url:"", 
        tag_type:"personal", status: 'active',});
    }

    return (
        <>
            <Dialog open={open} handler={handleOpen}>
                <div className="flex items-center justify-between">
                    <DialogHeader>{editTags ? 'Edit' : 'Add'} Tool: <span className='text-indigo-500 ml-2'>{editTags?.title}</span></DialogHeader>
                    <XMarkIcon className="mr-3 h-5 w-5" onClick={handleOpen} />
                </div>
                <DialogBody divider>
                    <div className="grid grid-cols-2 gap-6">
                        <Input label="title" name='title' value={title} onChange={handleChange} required/>
                        <Input label="Color" name='color' value={color} onChange={handleChange} required/>
                        <Input label="background color" name='bgColor' value={bgColor} onChange={handleChange} required/>
                        <select 
                        name="status"
                        value={status} onChange={handleChange}
                        className="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <option value="" disabled>Status</option>
                            <option value="active">Active</option>
                            <option value="not active">Not Active</option>
                        </select>
                        <select 
                        name="tag_type"
                        value={tag_type} onChange={handleChange}
                        className="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <option value="" disabled>Tag Type</option>
                            <option value="personal">Personal</option>
                            <option value="library">Library</option>
                        </select>
                    </div>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="outlined" color="red" onClick={handleOpen}>
                        close
                    </Button>
                    <Button variant="gradient" color="green" onClick={()=>submitFunction(editTags ? 'edit' : 'post')}>
                    {editTags ? 'Update' : 'Add'}
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default AddTag