import { Helmet } from 'react-helmet-async';
import Plans from '../managePlans/Plans';
import Tones from '../manageTones/Tones';
import { useEffect } from 'react';

const AdminPlanTones = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);
  return (
    <>
    <Helmet>
        <title>Plans & Tones | AskSophia</title>
    </Helmet>
    <Plans />
    <Tones />
</>
  )
}

export default AdminPlanTones