import Header from "./Header"
import Sidebar from "./Sidebar"

const Layout = ({ children }) => {
    return (
        <>
            <div className="flex w-full">
                <Sidebar />
                <div className="lg:ml-[288px] flex flex-col w-full">
                    <Header />
                    {children}
                </div>
            </div>
        </>
    )
}
export default Layout