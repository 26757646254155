import { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

const AddCategory = ({ open, handleOpen, editCat, handleSubmit, handleClose }) => {
    const [formData, setFormData] = useState({
        title: '', text: '', icone: undefined, link: ""
    });

    const { title, text, link, icone } = formData;

    useEffect(() => {
        if (editCat) {
            setFormData({
                ...formData, _id: editCat._id, title: editCat.title,
                text: editCat.text, icone: editCat.icone, link: editCat.link
            })
        }
    }, [editCat])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const submitFunction = async (type) => {
        await handleSubmit(formData, type);
        setFormData({ title: '', description: '', icone: "fa-pen-nib", link: "" })
    }

    return (
        <>
            <Dialog open={open} handler={handleClose}>
                <div className="flex items-center justify-between">
                    <DialogHeader>{editCat ? 'Edit' : 'Add'} Category <span className='text-indigo-500 ml-2'>{editCat?.title}</span></DialogHeader>
                    <XMarkIcon className="mr-3 h-5 w-5" onClick={handleClose} />
                </div>
                <DialogBody divider>
                    <div className="grid grid-cols-2 gap-6">
                        <div className=''>
                            <label>Title of the category</label>
                            <input required type="text" value={title}
                                name="title"
                                placeholder="Freelancers/Parents/Writing"
                                className="outline-0 bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onChange={handleChange} />
                        </div>
                        <div className=''>
                            <label>Category Descritpion</label>
                            <input required type="text" value={text}
                                name="text"
                                placeholder="Freelancers/Parents/Writing"
                                className="outline-0 bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onChange={handleChange} />
                        </div>
                        <div className=''>
                            <label>Icon of the category</label>
                            <input required type="text" value={icone}
                                name="icon"
                                placeholder="fa-pen-nib"
                                className="outline-0 bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onChange={handleChange} />
                        </div>
                        <div className=''>
                            <label>Link of the category</label>
                            <input required type="text" value={link}
                                name="link"
                                placeholder="/parents OR /freelancers"
                                className="outline-0 bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onChange={handleChange} />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="outlined" color="red" onClick={handleClose}>
                        close
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => submitFunction(editCat ? 'edit' : 'post')}>
                        {editCat ? 'Update' : 'Add'}
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default AddCategory