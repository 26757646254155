
import { useEffect, useReducer, useState } from 'react'
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
    Card,
    Typography,
    Button,
    CardBody,
    CardFooter,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import { toast } from 'react-toastify';
import SearchBread from '../components/layouts/SearchBread';
import AddTag from './AddTag';
import { tagsReducer, initialState } from '../../Reducers/tagsReducer';
import { deleteTag, editTag, fetchAllTags, postTag } from '../../api/tagsService';

const TABLE_HEAD = ["Title", "Color", "Background color", "Status", "Actions"];


const Library = () => {
    const [tagState, dispatch] = useReducer(tagsReducer, initialState)
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [editTags, setEditTag] = useState(null);
    const [open, setOpen] = useState(false);
    const { tags, isError, isSucess, pages, message } = tagState;

    useEffect(() => {
        if (isError) {
            toast.error(message)
        } else if (isSucess) {
            toast.success("Tool deleted")
        }
        dispatch({ type: "RESET" })
    }, [isError, isSucess, dispatch, message]);

    const removeTag = async (id) => {
        alert("Are you sure you want to delete this tool?")
        await deleteTag(id, dispatch);
    }

    const handleOpen = (data) => {
        if (data) {
            setEditTag(data)
        }
        setOpen(!open)
    }

    const getTags = async () => {
        await fetchAllTags(dispatch, page, "library");

    }

    useEffect(() => {
        getTags()
    }, [page])

    const handleSubmit = async (data, type) => {
        if (type === "edit") {
            await editTag(data, dispatch);
        } else {
            await postTag(data, dispatch);
        }
        setEditTag(null)
        handleOpen()
        getTags()
    }

    return (
        <>
            <section className="bg-[#F8F8F8] py-[30px] px-[40px] min-h-screen">
                <SearchBread title="Template Library" searchText={searchText} setSearchText={setSearchText} />
                <div onClick={() => handleOpen(null)}
                    className='cursor-pointer w-[200px] text-center text-white bg-blue-400 rounded-full mb-4 px-4 py-2'>Add New Tag</div>

                {tagState && tags && tags.length > 0 ? <Card className="h-auto w-full">
                    <CardBody className="overflow-scroll px-0">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal flex justify-center leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tags.map(
                                    (tag, index) => {
                                        const isLast = index === tags.length - 1;
                                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                        return (
                                            <tr key={tag._id}>
                                                <td className={`${classes} text-center`}>
                                                    <div className="flex justify-center items-center gap-3">
                                                        <p className={`text-sm font-bold`} style={{ color: tag.color }}>
                                                            {tag.title}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className={`${classes} text-center`}>
                                                    <div className="flex justify-center items-center gap-3">
                                                        <div style={{ backgroundColor: tag.color }}
                                                            className='rounded-full w-[20px] h-[20px]'>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={`${classes} text-center`}>
                                                    <div className="flex justify-center items-center gap-3">
                                                    <div style={{ backgroundColor: tag.bgColor }}
                                                            className='rounded-full w-[20px] h-[20px]'>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={`${classes} text-center`}>
                                                    <Typography variant="small" color={`${tag.status === "active" ? 'green' : 'red'}`} className="font-normal">
                                                        {tag.status}
                                                    </Typography>
                                                </td>
                                                <td className={`${classes} flex justify-center`}>
                                                    <Tooltip content="Edit Tag">
                                                        <IconButton onClick={() => handleOpen(tag)} variant="text" color="blue-gray">
                                                            <PencilIcon className="h-4 w-4" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip content="delete Tag">
                                                        <IconButton onClick={() => removeTag(tag._id)} variant="text" color="blue-gray">
                                                            <TrashIcon className="h-4 w-4" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter className={`${page === pages && page === 1 ? 'hidden' : 'flex'}  items-center justify-end border-t border-blue-gray-50 p-4`}>
                        <Button onClick={()=>setPage(page-1)} disabled={page === 1} className='mr-2' variant="outlined" color="blue-gray" size="sm">
                            Previous
                        </Button>
                        <Button onClick={()=>setPage(page+1)} disabled={page === pages} variant="outlined" color="blue-gray" size="sm">
                            Next
                        </Button>
                    </CardFooter>
                </Card> : <>
                    <div className="flex flex-col items-center justify-center">
                        <img src="/images/icons/saved.png" alt="empty" className="my-4" />
                        <p className="text-[#707070] text-[20px] font-medium">No Tags Found</p>
                    </div>
                </>}
            </section>
            <AddTag
                open={open}
                editTags={editTags}
                handleOpen={handleOpen}
                handleSubmit={handleSubmit} />
        </>
    )
}

export default Library