import axios from "axios";
axios.defaults.withCredentials = true;

export const getTotalUsers = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}users/total`;
    try {
        const response = await axios.get(url);
        return response.data.total;
    } catch (error) {
        console.log(error)
    }
}
export const getTotalProjects = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}projects/total`;
    try {
        const response = await axios.get(url);
        return response.data.total;
    } catch (error) {
        console.log(error)
    }
}
export const getTotalPrompts = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}prompts/total`;
    try {
        const response = await axios.get(url);
        return response.data.total;
    } catch (error) {
        console.log(error)
    }
}