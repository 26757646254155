import { Helmet } from 'react-helmet-async';
import Categories from '../manageCategories/Categories';
import Tools from '../manageTools/Tools';

const AdminCatTools = () => {
  return (
    <>
        <Helmet>
            <title>Tools & Categories | AskSophia</title>
        </Helmet>
        <Categories />
        <Tools />
    </>
  )
}

export default AdminCatTools