export const admin_sidebar =[
    {
        id: 1,
        title: "Dashboard",
        url:"/admin/dashboard"

    },
    {
        id: 2,
        title: "All Users",
        url:"/admin/users"
    },
    {
        id: 4,
        title: "Manage Prompts",
        url:"/admin/prompts"
    },
    {
        id: 5,
        title: "Manage Tools & Categories",
        url:"/admin/tools"
    },
    {
        id: 7,
        title: "Manage Tools Desc",
        url:"/admin/descriptions"
    },
    {
        id: 8,
        title: "Manage Templates",
        url:"/admin/tags"
    },
    {
        id: 10,
        title: "Manage Plans & Tones",
        url:"/admin/plans-tones"
    },
]

