
import { useEffect, useReducer, useState } from 'react'
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
    Card,
    Typography,
    Button,
    CardBody,
    CardFooter,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import { toast } from 'react-toastify';
import SearchBread from '../components/layouts/SearchBread';
import AddCategory from './AddCategory';
import { deleteCategory, editCategory, fetchAllCategories, postCategory } from '../../api/categoryService';
import { categoryReducer, initialState } from '../../Reducers/categoryReducer';

const TABLE_HEAD = ["title", "description" ,"Actions"];


const Categories = () => {
    const [catState, dispatch] = useReducer(categoryReducer, initialState)
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [editCat, setEditCat] = useState(null);
    const [open, setOpen] = useState(false);
    const { categories, isError, isSucess, pages, message } = catState;

    useEffect(() => {
        if(isError){
            toast.error(message)
        } else if(isSucess) {
            toast.success("Category deleted")
        }
        dispatch({type:"RESET"})
    }, [isError, isSucess , dispatch]);

    const removeCategory = async (id) => {
        alert("Are you sure you want to delete this tool?")
        await deleteCategory(id, dispatch);
    }

    const handleOpen = (data) => {
       if(data){
        setEditCat(data)
       }
        setOpen(!open)
    }

    const handleClose = () => {
        setEditCat(null)
        setOpen(!open)
    }

    const getCategories = async () => {
        await fetchAllCategories(dispatch, page);
    }

    useEffect(() => {
        getCategories()
    }, [page])

    const handleSubmit = async (data, type)=>{
       if(type==="edit"){
        await editCategory(data, dispatch);
       } else {
        await postCategory(data, dispatch);
       }
        handleClose()
        getCategories()
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <section className="bg-[#F8F8F8] py-[30px] px-[40px] min-h-screen">
                <SearchBread title="Categories List" searchText={searchText} setSearchText={setSearchText} />
                <div onClick={()=>handleOpen(null)}
                className='cursor-pointer w-[200px] text-center text-white bg-blue-400 rounded-full mb-2 px-4 py-2'>Add New Category</div>

          {catState && categories && categories.length > 0 ? <Card className="h-auto w-full">
                <CardBody className="overflow-scroll px-0">
                    <table className="w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal flex justify-center leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {categories.map(
                                (category, index) => {
                                    const isLast = index === categories.length - 1;
                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={category._id}>
                                             
                                            <td className={`${classes} text-center`}>
                                            <div className="flex justify-center items-center gap-3">
                                                    <Typography variant="small" color="pink" className="font-bold">
                                                        {category.title}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={`${classes} text-center`}>
                                                <div className="flex justify-center items-center gap-3">
                                                    <Typography variant="small" color="blue" className="font-bold">
                                                        {category.description}
                                                    </Typography>
                                                </div>
                                            </td>
                                        
                                            <td className={`${classes} flex justify-center`}>
                                            <Tooltip content="Edit Category">
                                                   <IconButton onClick={()=>handleOpen(category)} variant="text" color="blue-gray">
                                                        <PencilIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip>
                                              <Tooltip content="delete Category">
                                                    <IconButton  onClick={()=>removeCategory(category._id)} variant="text" color="blue-gray">
                                                        <TrashIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                </CardBody>
                <CardFooter className={`${page === pages && page === 1 ? 'hidden' :'flex'}  items-center justify-end border-t border-blue-gray-50 p-4`}>
                    <Button onClick={()=>setPage(page-1)} disabled={page === 1} className='mr-2' variant="outlined" color="blue-gray" size="sm">
                        Previous
                    </Button>
                    <Button onClick={()=>setPage(page+1)} disabled={page === pages} variant="outlined" color="blue-gray" size="sm">
                        Next
                    </Button>
                </CardFooter>
            </Card> : <>
            <div className="flex flex-col items-center justify-center">
                <img src="/images/icons/saved.png" alt="empty" className="my-4" />
                <p className="text-[#707070] text-[20px] font-medium">No Categories Found</p>
            </div>
            </>}
            </section>
        <AddCategory 
        open={open}
        editCat={editCat} 
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleSubmit={handleSubmit} />
        </>
    )
}

export default Categories