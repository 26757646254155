
import { useEffect, useReducer, useState } from 'react'
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
    Card,
    Typography,
    Button,
    CardBody,
    CardFooter,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import { toast } from 'react-toastify';
import SearchBread from '../components/layouts/SearchBread';
import EditPlan from './EditPlan';
import { planReducer, initialState } from '../../Reducers/planReducer';
import { deletePlan, editPlan, fetchAllPlans, postPlan } from '../../api/PlanService';

const TABLE_HEAD = ["Title", "Plan Id", "Price", "Pros", "Actions"];


const Plans = () => {
    const [planState, dispatch] = useReducer(planReducer, initialState)
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [editPlans, setEditPlan] = useState(null);
    const [open, setOpen] = useState(false);
    const { plans, isError, isSucess, pages, message } = planState;

    useEffect(() => {
        if (isError) {
            toast.error(message)
        } else if (isSucess) {
            toast.success("Tool deleted")
        }
        dispatch({ type: "RESET" })
    }, [isError, isSucess, dispatch, message]);

    const removePlan = async (id) => {
        alert("Are you sure you want to delete this tool?")
        await deletePlan(id, dispatch);
    }

    const handleOpen = (data) => {
        if (data) {
            setEditPlan(data)
        }
        setOpen(!open)
    }
    const handleClose = () => {
        setEditPlan(null)
        setOpen(!open)
    }
    const getPlans = async () => {
        await fetchAllPlans(dispatch, page);

    }

    useEffect(() => {
        getPlans()
    }, [page])

    const handleSubmit = async (data, type) => {
        if (type === "edit") {
            await editPlan(data, dispatch);
        } else {
            await postPlan(data, dispatch);
        }
        handleClose()
        getPlans()
    }


    return (
        <>
            <section className="bg-[#F8F8F8] py-[30px] px-[40px]">
                <SearchBread title="Plans List" searchText={searchText} setSearchText={setSearchText} />
                <div onClick={() => handleOpen(null)}
                    className='cursor-pointer w-[200px] text-center text-white bg-blue-400 rounded-full mb-2 px-4 py-2'>Add New Plan</div>

                {planState && plans && plans.length > 0 ? <Card className="h-auto w-full">
                    <CardBody className="overflow-scroll px-0">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal flex justify-center leading-none opacity-70"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {plans.map(
                                    (plan, index) => {
                                        const isLast = index === plans.length - 1;
                                        const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
                                        return (
                                            <tr key={plan._id}>
                                                <td className={`${classes} text-center`}>
                                                    <div className="flex justify-center items-center gap-3">
                                                        <p className={`text-sm font-bold`}>
                                                            {plan.title}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className={`${classes} text-center`}>
                                                    <div className="flex justify-center items-center gap-3">
                                                        <p className={`text-sm font-bold`}>
                                                            {plan.plan_id}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className={`${classes} text-center`}>
                                                    <div className="flex justify-center items-center gap-3">
                                                        <p className={`text-sm font-bold`}>
                                                            {plan.price}
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className={`${classes} text-center`}>
                                                    <div className="flex justify-center items-center gap-3">
                                                    {plan.pros.map((item, index)=>{
                                                        return <p key={index} className={`text-sm font-bold`}>
                                                        {item}
                                                    </p>
                                                    })}
                                                    </div>
                                                </td>
                                                <td className={`${classes} flex justify-center`}>
                                                    <Tooltip content="Edit Plan">
                                                        <IconButton onClick={() => handleOpen(plan)} variant="text" color="blue-gray">
                                                            <PencilIcon className="h-4 w-4" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip content="delete Plan">
                                                        <IconButton onClick={() => removePlan(plan._id)} variant="text" color="blue-gray">
                                                            <TrashIcon className="h-4 w-4" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </CardBody>
                    <CardFooter className={`${page === pages && page === 1 ? 'hidden' : 'flex'}  items-center justify-end border-t border-blue-gray-50 p-4`}>
                        <Button onClick={()=>setPage(page-1)} disabled={page === 1} className='mr-2' variant="outlined" color="blue-gray" size="sm">
                            Previous
                        </Button>
                        <Button onClick={()=>setPage(page+1)} disabled={page === pages} variant="outlined" color="blue-gray" size="sm">
                            Next
                        </Button>
                    </CardFooter>
                </Card> : <>
                    <div className="flex flex-col items-center justify-center">
                        <img src="/images/icons/saved.png" alt="empty" className="my-4" />
                        <p className="text-[#707070] text-[20px] font-medium">No Plans Found</p>
                    </div>
                </>}
            </section>
            <EditPlan
                open={open}
                editPlan={editPlans}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleSubmit={handleSubmit} />
        </>
    )
}

export default Plans