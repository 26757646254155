import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import Header from '../layouts/Header'
import { ContactEmail } from '../../../api/userService'
import { toast } from 'react-toastify'

const Contact = () => {

    const [formData, setFormData] = useState({
        firstname: '', lastname: '', subject: '', email: '', message: ''
    })

    const { firstname, lastname, subject, email, message } = formData;

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await ContactEmail(formData);
        if(res.status===200){
            setFormData({firstname: '', lastname: '', subject: '', email: '', message: ''})
            toast.success(res.data.message, {theme:"dark", position:'bottom-center'})
        } else {
            toast.error('Something went wrong', {theme:"dark", position:'bottom-center'})
        }
    }
    return (
        <>
            <Helmet>
                <title>
                    Contact Us | AskSophia
                </title>
            </Helmet>
            <section className='lg:mx-[133px] mx-[80px] mt-[42px] h-screen'>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                    <div>
                        <h3 className='lg:w-[500px] text-[36px] leading-[40px] font-bold mb-[16px]'>We love your hear from you, Get in touch</h3>
                        <p className='text-[#585757] text-[16px] leading-[22px]'>Let us know about your problems</p>
                    </div>
                    <div className='w-96'></div>
                </div>
                <div className='mt-[50px]'>
                    <form onSubmit={handleSubmit}>
                        <div className='grid lg:grid-cols-2 grid-cols-1 gap-6'>
                            <div className="mb-6">
                                <label className="mb-2 block font-semibold text-black text-[18px] leading-[24px]">
                                    First Name
                                </label>
                                <input
                                    type='text'
                                    value={firstname}
                                    onChange={handleChange}
                                    name="firstname"
                                    placeholder="First Name"
                                    className="rounded-[10px] resize-none w-full p-4 outline-0 text-md border border-gray-100 placeholder:text-gray-400"
                                />
                            </div>
                            <div className="mb-6">
                                <label className="mb-2 block font-semibold text-black text-[18px] leading-[24px]">
                                    Last Name
                                </label>
                                <input
                                    type='text'
                                    value={lastname}
                                    onChange={handleChange}
                                    name="lastname"
                                    placeholder="Last Name"
                                    className="rounded-[10px] resize-none w-full p-4 outline-0 text-md border border-gray-100 placeholder:text-gray-400"
                                />
                            </div>
                            <div className="mb-6">
                                <label className="mb-2 block font-semibold text-black text-[18px] leading-[24px]">
                                    Subject
                                </label>
                                <input
                                    type='text'
                                    onChange={handleChange}
                                    value={subject}
                                    name="subject"
                                    placeholder="Subject"
                                    className="rounded-[10px] resize-none w-full p-4 outline-0 text-md border border-gray-100 placeholder:text-gray-400"
                                />
                            </div>
                            <div className="mb-6">
                                <label className="mb-2 block font-semibold text-black text-[18px] leading-[24px]">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    onChange={handleChange}
                                    value={email}
                                    name="email"
                                    placeholder="Email"
                                    className="rounded-[10px] resize-none w-full p-4 outline-0 text-md border border-gray-100 placeholder:text-gray-400"
                                />
                            </div>
                        </div>
                        <div className="">
                                <label className="mb-2 block font-semibold text-black text-[18px] leading-[24px]">
                                    Describe problem
                                </label>
                                <textarea
                                    rows="4"
                                    onChange={handleChange}
                                    value={message}
                                    name="message"
                                    placeholder="Write down your problem here..."
                                    className="rounded-[10px] resize-none w-full p-4 outline-0 text-md border border-gray-100 placeholder:text-gray-400"
                                />
                            </div>
                            <div className='w-full flex justify-end mt-4'>
                                <button className='text-white bg-blue-400 rounded-full px-4 py-2'>Send</button>
                            </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default Contact