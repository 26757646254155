import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
    EditNoteOutlined, BookmarkBorderOutlined,
    MarkChatUnreadOutlined, AutoFixHighOutlined,
    StarBorderOutlined, ContactSupportOutlined,
    CampaignOutlined, LibraryBooksOutlined,
    PersonOutlineOutlined, LogoutOutlined,
    SubscriptionsOutlined
} from '@mui/icons-material';

const MobileNav = ({open, setOpen, onLogout}) => {
  return (
    <nav className="mobileNav h-full mx-auto flex lg:hidden justify-between items-center 2xl:py-4 py-3">
                <Link to="/chat" className="flex items-center justify-center cursor-pointer">
                    <img src="/images/asksophialogo.jpeg" className="w-[150px]" alt="AskSophia logo" />
                </Link>

                <div onClick={() => setOpen(!open)} className="text-3xl absolute right-8 top-5 cursor-pointer lg:hidden">
                    <i className={`fa-solid fa-${open ? 'x' : 'bars'} text-black`}></i>
                </div>

                <div className={`shadow-lg lg:z-auto z-[12] left-0 w-full lg:w-auto px-12 transition-all border-b border-gray-200 duration-500 ease-in lg:flex lg:pb-0 pb-12 absolute lg:static bg-white lg:items-center text-md ${open ? 'top-20' : 'top-[-490px] md:opacity-100 opacity-0'}`}>
                    <div className="flex flex-col">
                        <NavLink to="/chat" className="flex my-4 items-center cursor-pointer">
                            <span className="mr-3">
                                <MarkChatUnreadOutlined />
                            </span>
                            <p className="text-[16px] leading-[24px]">Chat</p>
                        </NavLink>
                        <NavLink to="/tools" className="flex mb-4 text-gray-400 items-center cursor-pointer">
                            <span className="mr-3">
                                <AutoFixHighOutlined />
                            </span>
                            <p className="text-[16px] leading-[24px]">Tools</p>
                        </NavLink>
                        <NavLink to="/templates" className="flex text-gray-400 mb-4 items-center cursor-pointer">
                            <span className="mr-3">
                                <BookmarkBorderOutlined />
                            </span>
                            <p className="text-[16px] leading-[24px]">All Templates</p>
                        </NavLink>
                        <NavLink to="/post-prompt" className="flex mb-4 text-gray-400 items-center cursor-pointer">
                            <span className="mr-3">
                            <EditNoteOutlined />
                            </span>
                            <p className="text-[16px] leading-[24px]">Post Prompt</p>
                        </NavLink>
                        <NavLink to="/contact-us" className="flex mb-4 items-center text-gray-400 cursor-pointer">
                        <span className="mr-3">
                            <CampaignOutlined />
                        </span>
                        <p className="text-[16px] leading-[24px]">Feedback</p>
                    </NavLink>
                    <div className="flex items-center relative">
                        <div className='mr-3'>
                        <img className="peer cursor-pointer w-8 h-8 rounded-full" src="/images/sophia.png" alt="user" />
                        <div style={{ zIndex: 12 }}
                         className="hidden peer-hover:flex hover:flex
                        w-[230px] top-8 left-0 absolute p-4 rounded-[10px]
                        flex-col bg-white drop-shadow-lg">
                            <Link to="/profile" className="flex items-center p-2 hover:bg-gray-200">
                                <span className="mr-3">
                                    <PersonOutlineOutlined />
                                </span>
                                Profile</Link>
                            <Link to="/plans" className="flex items-center bg-blue-500 hover:bg-blue-600 rounded-lg p-2 text-white">
                                <span className="mr-3">
                                    <StarBorderOutlined sx={{ color: "white" }} />
                                </span>
                                Upgrade</Link>
                                <Link to="/subscription" className="flex items-center p-2 hover:bg-gray-200">
                                <span className="mr-3">
                                    <SubscriptionsOutlined />
                                </span>
                                Plan</Link>
                            <Link to="/contact-us" className="flex items-center p-2 hover:bg-gray-200">
                                <span className="mr-3">
                                    <ContactSupportOutlined />
                                </span>
                                Contact us</Link>
                            <p onClick={onLogout} className="flex text-gray-400 cursor-pointer items-center p-2 hover:bg-gray-200">
                                <span className="mr-3">
                                    <LogoutOutlined />
                                </span>
                                Log Out</p>
                        </div>
                        </div>
                        <p className=" text-gray-400 cursor-pointer text-[16px] leading-[24px]">Profile</p>
                    </div>
                    </div>
                </div>
            </nav>
  )
}

export default MobileNav