import { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

const EditPlan = ({ open, handleOpen, editTone, handleSubmit, handleClose }) => {

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        if (editTone) {
            setTitle(editTone.title);
            setDescription(editTone.description);
            }
    }, [editTone])

    const submitFunction = async (type) => {
        await handleSubmit({_id:editTone?._id, title, description}, type);
        setTitle("");
        setDescription("");
    }

    return (
        <>
            <Dialog open={open} handler={handleOpen}>
                <div className="flex items-center justify-between">
                    <DialogHeader>{editTone ? 'Edit' : 'Add'} Tone</DialogHeader>
                    <XMarkIcon className="mr-3 h-5 w-5" onClick={handleClose} />
                </div>
                <DialogBody divider>
                    <div className="grid grid-cols-1 gap-6">
                    <div className="mb-6">
                            <label htmlFor="title" className="block mb-[10px] text-[15px] font-semibold text-gray-900">Title</label>
                                <div className="flex">
                                    <input
                                        required
                                        type="text"
                                        name="title"
                                        value={title}
                                        placeholder='Friendly'
                                        onChange={(e) => setTitle(e.target.value)}
                                        className="mr-[20px] mb-[8px] border border-gray-500 outline-0 text-gray-800 rounded-[8px] py-[14px] px-4 w-full"
                                    />
                                </div>
                        </div>            
                    <div className="">
                            <label htmlFor="description" className="block mb-[10px] text-[15px] font-semibold text-gray-900">Title</label>
                                <div className="flex">
                                    <input
                                        required
                                        type="text"
                                        name="description"
                                        value={description}
                                        placeholder="This tone shows understanding and sympathy for another's feelings"
                                        onChange={(e) => setDescription(e.target.value)}
                                        className="mr-[20px] mb-[8px] border border-gray-500 outline-0 text-gray-800 rounded-[8px] py-[14px] px-4 w-full"
                                    />
                                </div>
                        </div>            
                    </div>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="outlined" color="red" onClick={handleClose}>
                        close
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => submitFunction(editTone ? 'edit' : 'post')}>
                        {editTone ? 'Update' : 'Add'}
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default EditPlan