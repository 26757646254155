import { useEffect } from "react"
import { getTotalProjects, getTotalPrompts, getTotalUsers } from "../api/totalService";

const useGetTotal = (setTotals) => {

 useEffect(()=>{
    const getAllTotals = async ()=>{
        const users = await getTotalUsers();
        const projects = await getTotalProjects();
        const prompts = await getTotalPrompts();
        setTotals({users, prompts, projects})
    }
    getAllTotals()
 },[]);

}

export default useGetTotal