import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/index.css';

import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from "@material-tailwind/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ThemeProvider>
);
reportWebVitals();
