
const DashboardCards = ({title, total}) => {
    return (
        <div className='flex items-center bg-white rounded-[10px] px-4 py-3 border border-gray-100'>
            <img
                className="w-[60px]"
                src="/images/blog.png"
                alt="blog icon"
            />
            <div className='flex flex-col ml-[12px]'>
                <h3 className='text-[15px] font-semibold'>{title}</h3>
                <p className='text-gray-300 text-[13px]'>{total}</p>
            </div>
        </div>
    )
}

export default DashboardCards