import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from "./auth/Login";
import { createContext, useReducer } from "react";
import { initialState, authReducer } from "./Reducers/authReducer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./admin/components/layouts/Layout";
import useCache from "./hooks/useCache";
import Stepper from "./auth/Stepper";
import PrivateAdminRoute from "./admin/components/essentials/PrivateAdminRoute";
import AdminDashboard from "./admin/pages/AdminDashboard";
import AdminUsers from "./admin/pages/AdminUsers";
import VerifyEmail from "./admin/components/essentials/VerifyEmail";
import IndexRoute from "./admin/components/essentials/IndexRoute";
import AdminCatTools from "./admin/pages/AdminCatTools";
import ToolsDesc from "./admin/manageToolsDescription/ToolsDesc";
import Tags from "./admin/manageTags/Tags";
import Prompts from "./admin/managePrompts/Prompts";
import AdminPlanTones from "./admin/pages/AdminPlanTones";
import Contact from "./admin/components/essentials/Contact";

export const UserContext = createContext();

function App() {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useCache();

  return (
    <>
      <Router>
        <UserContext.Provider
          value={{ state, dispatch }}
        >
          <ToastContainer />
          <Routes>
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/sign-up" element={<Stepper />} />
            <Route path="/:id/verify/:token" element={<VerifyEmail />} />
            <Route path="/" element={<IndexRoute />} />
              <Route
                path="/admin/*"
                element={
                  <>
                    <Layout>
                      <Routes>
                      <Route path="" element={<PrivateAdminRoute />}>
                        <Route path="/dashboard" element={<AdminDashboard />} />
                        <Route path="/contact-us" element={<Contact />} />
                        <Route path="/users" element={<AdminUsers />} />
                        <Route path="/tools" element={<AdminCatTools />} />
                        <Route path="/descriptions" element={<ToolsDesc />} />
                        <Route path="/tags" element={<Tags />} />
                        <Route path="/prompts" element={<Prompts />} />
                        <Route path="/plans-tones" element={<AdminPlanTones />} />
                      </Route>
                      </Routes>
                    </Layout>
                  </>
                }
              />
          </Routes>
        </UserContext.Provider>
      </Router>
    </>
  );
}

export default App;
