
import { useEffect, useReducer, useState } from 'react'
import Library from './Library';
import Personal from './Personal';



const Tags = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <>
           <Library /> 
           <Personal />
        </>
    )
}

export default Tags