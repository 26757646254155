
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { UserContext } from '../../App';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { PencilIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
    Card,
    Typography,
    Button,
    CardBody,
    CardFooter,
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import { toast } from 'react-toastify';
import SearchBread from '../../admin/components/layouts/SearchBread';
import { authReducer, initialState } from '../../Reducers/authReducer';
import { deleteUser, fetchUsers, putUser } from '../../api/userService';
import EditUser from '../components/Modals/EditUser';

const TABLE_HEAD = ["Role", "Username", "Email", "Date","Status", "Plan", "Words" ,"Actions"];


const AdminUsers = () => {
    const {state} = useContext(UserContext)
    const [userState, dispatch] = useReducer(authReducer, initialState)
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [editUser, setEditUser] = useState(null);
    const [open, setOpen] = useState(false);
    const { users, isError, isSucess, pages } = userState;

    useEffect(() => {
        if(isError){
            toast.error("An error occured")
        } else if(isSucess) {
            toast.success("User deleted")
        }
        dispatch({type:"RESET"})
    }, [isError, isSucess , dispatch]);

    const removeUser = async (id) => {
        alert("Are you sure you want to delete this user?")
        await deleteUser(id, dispatch);
    }

    const handleOpen = (user) => {
        setEditUser(user)
        setOpen(!open)
    }

    const getUsers = async () => {
        await fetchUsers(dispatch, page);
    }

    useEffect(() => {
        getUsers()
    }, [page])

    const handleSubmit = async (role, userData, dispatch)=>{
        await putUser(role, userData, dispatch);
        handleOpen()
        getUsers()
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <Helmet>
                <title>All Users | AskSophia</title>
            </Helmet>
            <section className="bg-[#F8F8F8] py-[30px] px-[40px] min-h-screen">
                <SearchBread title="Users List" searchText={searchText} setSearchText={setSearchText} />
          { userState && users && users.length > 0 ? <Card className="h-auto w-full">
                <CardBody className="overflow-scroll px-0">
                    <table className="w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal flex justify-center leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(
                                (user, index) => {
                                    const isLast = index === users.length - 1;
                                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <tr key={user._id}>
                                            <td className={`${classes} text-center`}>
                                            <div className="flex items-center gap-3">
                                                    <Typography variant="small" color="pink" className="font-bold">
                                                        {user.role}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={`${classes} text-center`}>
                                                
                                                <div className="flex items-center gap-3">
                                                    <Typography variant="small" color="purple" className="font-bold">
                                                        {user.username}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={`${classes} text-center`}>
                                                <div className="flex items-center gap-3">
                                                    <Typography variant="small" color="blue" className="font-bold">
                                                        {user.email}
                                                    </Typography>
                                                </div>
                                            </td>
                                           
                                            <td className={`${classes} text-center`}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                {moment(user.createdAt).fromNow()}
                                                </Typography>
                                            </td>
                                            <td className={`${classes} text-center`}>
                                                <Typography variant="small" color={`${user.status==="active" ? 'green' : 'red'}`} className="font-normal">
                                                    {user.status}
                                                </Typography>
                                            </td>
                                            <td className={`${classes} text-center`}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {user.plan}
                                                </Typography>
                                            </td>
                                            <td className={`${classes} text-center`}>
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {user.words}
                                                </Typography>
                                            </td>
                                            <td className={`${classes} flex justify-center`}>
                                            <Tooltip content="Edit User">
                                                   <IconButton onClick={()=>handleOpen(user)} variant="text" color="blue-gray">
                                                        <PencilIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip>
                                               {state.user._id !== user._id && <Tooltip content="delete User">
                                                    <IconButton  onClick={()=>removeUser(user._id)} variant="text" color="blue-gray">
                                                        <TrashIcon className="h-4 w-4" />
                                                    </IconButton>
                                                </Tooltip>}
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                </CardBody>
                <CardFooter className={`${page === pages && page === 1 ? 'hidden' :'flex'}  items-center justify-end border-t border-blue-gray-50 p-4`}>
                    <Button className='mr-2' variant="outlined" color="blue-gray" size="sm">
                        Previous
                    </Button>
                    <Button variant="outlined" color="blue-gray" size="sm">
                        Next
                    </Button>
                </CardFooter>
            </Card> : <>
            <div className="flex flex-col items-center justify-center">
                <img src="/images/icons/saved.png" alt="empty" className="my-4" />
                <p className="text-[#707070] text-[20px] font-medium">No Users Found</p>
            </div>
            </>}
            </section>
        <EditUser open={open} user={editUser} handleOpen={handleOpen} handleSubmit={handleSubmit} />
        </>
    )
}

export default AdminUsers