import { Line } from "react-chartjs-2";

import {  Chart as ChartJS,
    CategoryScale, LinearScale,
    PointElement, LineElement,
    Title, Tooltip, Legend,} from 'chart.js';

ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

const labels = [ 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [5,2,3,4,8],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: [12,1,5,3,8],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};
const DashboardCharts = () => {
    return (
        <div className="flex flex-col bg-white rounded-[10px] px-4 py-3 border border-gray-100">
            <h3 className="text-gray-700 text-[18px] font-bold">Weekly Revenue</h3>
            <Line
                data={data}
                options={options}
            />
        </div>
    )
}

export default DashboardCharts