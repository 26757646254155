import { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

const EditPrompt = ({ open, handleOpen, editPrompt, handleSubmit, handleClose }) => {
    const [formData, setFormData] = useState({
        title: '', description: '', template:''
    });

    const { title, description, template } = formData;

    useEffect(() => {
        if (editPrompt) {
            setFormData({
                ...formData, _id: editPrompt._id, title: editPrompt.title,
                description: editPrompt.description, 
                template: editPrompt.template, 
            })
        }
    }, [editPrompt])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const submitFunction = async () => {
        await handleSubmit(formData._id, formData);
        setFormData({ title: '', description: '' })
    }

    return (
        <>
            <Dialog open={open} handler={handleOpen} size="xl">
                <div className="flex items-center justify-between">
                    <DialogHeader>Edit Prompt</DialogHeader>
                    <XMarkIcon className="mr-3 h-5 w-5" onClick={handleClose} />
                </div>
                <DialogBody divider>
                    <div className="grid grid-cols-1 gap-2">
                        <div>
                            <label>Prompt Title</label>
                            <input type="text" name='title'
                                className="outline-0 bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={title} onChange={handleChange} required />
                        </div>
                        <div>
                            <label>Prompt Descritpion</label>
                            <textarea
                                name="description"
                                rows="4"
                                className="outline-0 bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={description} onChange={handleChange} required />
                        </div>
                        <div>
                            <label>Prompt Template</label>
                            <textarea rows="8" name='template'
                                className="outline-0 bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={template} onChange={handleChange} required />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="outlined" color="red" onClick={handleClose}>
                        close
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => submitFunction(editPrompt)}>
                        Update
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default EditPrompt