
// Get user from localStorage
const user = JSON.parse(localStorage.getItem("admin__asksophia"));
export const initialState = {
    plans: [],
    pages: 0,
    user: user ? user : null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    search: '',
    message: ""
}

export const planReducer = (state, action) => {
    switch (action.type) {
        case "ADD_PLAN":
            return {
                ...state,
                isLoading: true,
                isError: false,
                isSuccess: true,
                message: "Plan Added successfully"
            }
        case "EDIT_PLAN":
            return {
                ...state,
                isLoading: true,
                isError: false,
                isSuccess: true,
                message: action.payload
            }

        case "DELETE_PLAN":
            return {
                ...state,
                plans: state.plans.filter((plan) => plan._id !== action.payload),
                isLoading: true,
                isError: false,
                isSuccess: true,
                message: "Plan deleted successfully"
            }
        
        case "FETCH_PLANS":
            return {
                ...state,
                plans: action.plans,
                pages: action.pages,
                isLoading: false,
                isError: false,
                isSuccess: true
            }
        case "ERROR":
            return {
                ...state,
                isLoading: false,
                isError: true,
                isSuccess: false,
                message: action.payload
            }
        case "RESET":
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: false,
                message: ""
            };

        default:
            throw new Error("You are dispatching something that is not in reducer");
    }
}