import { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { fetchAllCategories } from '../../api/categoryService';
import { categoryReducer, initialState } from '../../Reducers/categoryReducer';
import { useReducer } from 'react';
import Select from 'react-select'

const AddTool = ({ open, handleOpen, handleClose, editTools, handleSubmit }) => {

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [catState, dispatch] = useReducer(categoryReducer, initialState)
    const [categories, setCategories] = useState([]);

    const [formData, setFormData] = useState({
        title: '', icone: "fa-pen-nib", text: '', status: 'active',
        category: "64eba83eefb52db9cdd72739", link: ""
    });
    const { title, icone, link, text, status, category } = formData;

    useEffect(() => {
        if (editTools) {
            setFormData({
                ...formData, _id: editTools._id, title: editTools.title,
                category: editTools.category?._id ? editTools.category._id : "64eba83eefb52db9cdd72739", link: editTools.link, icone: editTools.icone ? editTools.icone : "fa-pen-nib",
                text: editTools.text, status: editTools.status
            })
        }
    }, [editTools])

    const getCategories = async () => {
        const response = await fetchAllCategories(dispatch, 0);
        setCategories(response);
    }

    useEffect(() => {
        getCategories()
    }, [])

    const handleChange = (e, fieldName) => {
        if (fieldName) {
            setFormData({ ...formData, [fieldName]: e ? e.value : null });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    }

    const submitFunction = async (type) => {
        await handleSubmit(formData, type);
        setFormData({
            title: '', icone: "fa-pen-nib", text: '', status: 'active',
            category: "64eba83eefb52db9cdd72739"
        });
    }

    return (
        <>
            <Dialog open={open} handler={handleOpen}>
                <div className="flex items-center justify-between">
                    <DialogHeader>{editTools ? 'Edit' : 'Add'} Tool: <span className='text-indigo-500 ml-2'>{editTools?.title}</span></DialogHeader>
                    <XMarkIcon className="mr-3 h-5 w-5" onClick={handleClose} />
                </div>
                <DialogBody divider>
                    <div className="grid grid-cols-2 gap-6">
                        <div className=''>
                            <label>Title of the tool</label>
                            <input required type="text" value={title}
                                name="title"
                                placeholder="Mommy Blog Inspirer"
                                className="outline-0 bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onChange={handleChange} />
                        </div>
                        <div className=''>
                            <label>Tool Descritpion</label>
                            <input required type="text" value={text}
                                name="text"
                                placeholder=""
                                className="outline-0 bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onChange={handleChange} />
                        </div>
                        <div className=''>
                            <label>Icon of the tool</label>
                            <input required type="text" value={icone}
                                name="icon"
                                placeholder="fa-pen-nib"
                                className="outline-0 bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onChange={handleChange} />
                        </div>
                        <div className=''>
                            <label>Link of the tool</label>
                            <input required type="text" value={link}
                                name="link"
                                placeholder="/mommy-blog-inspirer"
                                className="outline-0 bg-gray-50 mb-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                onChange={handleChange} />
                        </div>
                        <div>
                            <label>Status of the tool</label>
                            <select
                                name="status"
                                value={status} onChange={handleChange}
                                className="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option value="" disabled>Status</option>
                                <option value="active">Active</option>
                                <option value="not active">Not Active</option>
                            </select>
                        </div>
                       {catState && categories && categories.length > 0 && <div>
                            <label>Category that belongs to the tool</label>
                            <Select
                                value={selectedCategory}
                                onChange={(e) => {
                                    setSelectedCategory(e);
                                    handleChange(e, 'category');
                                }}
                                options={categories.map(cat => ({ value: cat._id, label: cat.title }))}
                                />
                        </div>}
                    </div>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="outlined" color="red" onClick={handleClose}>
                        close
                    </Button>
                    <Button variant="gradient" color="green" onClick={() => submitFunction(editTools ? 'edit' : 'post')}>
                        {editTools ? 'Update' : 'Add'}
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default AddTool